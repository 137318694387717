import React from 'react';
import { TableContainer, HorizontalScrollContainer, Table, TableHead, TableRow, TableHeader, TableBody } from '../styles/BomTableBodyStyles'; 
import BomAssetRow from './BomAssetRow';

const BomTableBody = ({ data = [] }) => (
  <>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Image</TableHeader>
            <TableHeader>Count</TableHeader>
            {/*<TableHeader>ID</TableHeader>*/}
            <TableHeader>Name</TableHeader>
            <TableHeader>Tags</TableHeader>
            <TableHeader>Cost</TableHeader>
            <TableHeader>Producer</TableHeader>
            <TableHeader>Designer</TableHeader>
            <TableHeader>Link</TableHeader>
            <TableHeader>L(cm)</TableHeader> 
            <TableHeader>W(cm)</TableHeader> 
            <TableHeader>H(cm)</TableHeader> 
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <BomAssetRow
              key={item.id}
              asset={item}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    
    <HorizontalScrollContainer>
      {/* Add an empty div or content to force horizontal scrollbar visibility if needed */}
    </HorizontalScrollContainer>
  </>
);

export default BomTableBody;
