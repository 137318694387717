import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import ScreenshotCard from './ScreenshotCard';
import { MediaPageContainer, Title, ScreenshotGrid } from '../styles/MediaPageStyles';
import { useApiClient } from '../hooks/useApiClient';

const MediaPage = () => {
    const { projectId, configurationId } = useParams(); // Retrieve both projectId and configurationId from URL
    const [screenshots, setScreenshots] = useState([]);
    const [activeMenuId, setActiveMenuId] = useState(null); // Track the active menu ID
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const apiClient = useApiClient();
    // Fetch screenshots from backend
    useEffect(() => {
        const fetchScreenshots = async () => {
            try {
                const response = await apiClient.get(`/projects/${projectId}/${configurationId}/media/`);
                console.log("Fetched screenshots:", response);
                const screenshotsData = response.data.screenshots || [];
                setScreenshots(screenshotsData);
                setLoading(false);
            } catch (error) {
                console.error("Failed to fetch screenshots:", error);
                setError('Failed to load media. Please try again later.');
                setLoading(false);
            }
        };

        fetchScreenshots();
    }, [projectId, configurationId]);


    // Delete screenshot by index
    const deleteScreenshot = async (index) => {
        try {
            await apiClient.delete(`/projects/${projectId}/${configurationId}/media/`, {
                data: { index },  // Send the index to delete the specific screenshot
            });

            // Remove the screenshot from the state to update the UI
            setScreenshots((prevScreenshots) => prevScreenshots.filter((_, i) => i !== index));
        } catch (error) {
            console.error("Failed to delete screenshot:", error);
            setError('Failed to delete screenshot. Please try again.');
        }
    };


    const toggleMenu = (id) => {
        setActiveMenuId((prevId) => (prevId === id ? null : id));
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <MediaPageContainer>
            <Title>Media Gallery</Title>
            <ScreenshotGrid>
                {screenshots.map((screenshot, index) => (
                    <ScreenshotCard 
                        key={index} 
                        screenshot={screenshot} 
                        isMenuOpen={activeMenuId === index}
                        toggleMenu={() => toggleMenu(index)}
                        deleteScreenshot={() => deleteScreenshot(index)}  // Pass delete handler
                   />
                ))}
            </ScreenshotGrid>
        </MediaPageContainer>
    );
};

export default MediaPage;
