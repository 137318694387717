// ChooseTemplate.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TemplateContainer, TemplateRow, TemplateCard, TemplateImage, InputContainer, TemplateTitle, ProjectInput, ErrorText, TemplateCardTitle, TemplateDimension, DimensionIcon, Overlay } from '../styles/ChooseTemplateStyles';
import { useApiClient } from '../hooks/useApiClient';

const ChooseTemplate = () => {
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const placeholderImage = `${process.env.PUBLIC_URL}/placeholder_small.jpg`;
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [projectName, setProjectName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const templates = [
    { id: 0, template: 'template1', displayName: 'Square Room', dimension: '4 x 4', icon: 'square_dimensions_icon.png' },
    { id: 1, template: 'template2', displayName: 'Rectangular Room', dimension: '4 x 7', icon: 'rectangle_dimensions_icon.png' }
  ];

  const handleTemplateClick = (templateId) => {
    setSelectedTemplate(templateId);
    setErrorMessage('');
  };

  const handleProjectNameChange = (e) => {
    setProjectName(e.target.value);
  };

  const handleSubmit = async () => {
    if (selectedTemplate === null || !projectName) {
      setErrorMessage('Please select a template and enter a project name.');
      return;
    }

    const formData = new FormData();
    formData.append('templateId', selectedTemplate);
    formData.append('projectName', projectName);

    try {
      const response = await apiClient.post('/projects/create_template/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        navigate('/projects', { replace: true });
      } else {
        setErrorMessage('An unexpected error occurred. Please try again.');
      }
    } catch (error) {
      const backendMessage = error.response?.data?.message || 'Failed to create project. Please try again.';
      console.error('Error creating project:', backendMessage);
      setErrorMessage(backendMessage);
    }
  };

  return (
    <TemplateContainer>
      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
      
      <InputContainer>
        <TemplateTitle>Enter Project Name</TemplateTitle>
        <ProjectInput 
          type="text" 
          value={projectName} 
          onChange={handleProjectNameChange} 
          placeholder="Project Name" 
        />
      </InputContainer>
      
      <h1>Choose a Template</h1>
      <TemplateRow>
        {templates.map(({ id, template, displayName, dimension, icon }) => (
          <TemplateCard 
            key={template}
            onClick={() => handleTemplateClick(id)}
            isSelected={selectedTemplate === id}
          >
            <TemplateImage 
              src={`${process.env.PUBLIC_URL}/${template}_img.png`} 
              onError={(e) => (e.target.src = placeholderImage)} 
              alt={displayName}
            />
            <Overlay /> {/* Dark overlay for readability */}
            <TemplateCardTitle>{displayName}</TemplateCardTitle>
            <TemplateDimension>
              <DimensionIcon src={`${process.env.PUBLIC_URL}/${icon}`} alt="Dimension Icon" />
              <span>{dimension}</span>
            </TemplateDimension>
          </TemplateCard>
        ))}
      </TemplateRow>
      
      <button onClick={handleSubmit} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px' }}>
        Continue
      </button>
    </TemplateContainer>
  );
};

export default ChooseTemplate;
