import React, { useState, useEffect } from 'react';
import { DashboardContainer, Header, SearchContainer, SearchInput, ProjectsContainer, CreateButton, ProjectsSection, InactiveProjectsSection } from '../styles/DashboardStyles';
import ProjectCard from './ProjectCard';
import { useApiClient } from '../hooks/useApiClient';  // Import the custom hook

const Dashboard = ({ setSelectedProject, activeSection, setActiveSection, selectedProject }) => {
  const [searchQuery, setSearchQuery] = useState('');  // State to keep track of the search query
  const [projects, setProjects] = useState([]);  // State to store projects
  const apiClient = useApiClient();  // Use the custom hook

  useEffect(() => {
    // Fetch projects from the backend API
    const fetchProjects = async () => {
      try {
        const response = await apiClient.get('/projects/');
        setProjects(response.data);  // Update the projects state with the data
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();  // Call the fetch function
  }, []);  

  // Filter projects based on the search query
  const filteredProjects = projects.filter(project => {
    const title = project.project_name ? project.project_name.toLowerCase() : '';
    const location = project.project_address ? project.project_address.toLowerCase() : '';
    const query = searchQuery.toLowerCase();
    
    return title.includes(query) || location.includes(query);
  });

  // Separate active and inactive projects
  const activeProjects = filteredProjects.filter(project => project.active);
  const inactiveProjects = filteredProjects.filter(project => !project.active);

  return (
    <DashboardContainer>
      {activeSection === 'projects' && (
        <Header>
          <h1>Projects Dashboard</h1>
          <SearchContainer>
            <CreateButton onClick={() => {
              setActiveSection('create_project');
              setSelectedProject(null); // Clear any selected project
            }}>Create Project</CreateButton>
            <SearchInput
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}  // Update search query as the user types
            />
          </SearchContainer>
        </Header>
      )}
      <ProjectsSection>
        <h2>Active Projects</h2>
        <ProjectsContainer>
          {activeProjects.map(project => (
            <ProjectCard
              key={project.id}
              project={project}
              configurations={project.configurations} // Pass configurations
              isSelected={selectedProject && selectedProject.id === project.id}
              onClick={() => setSelectedProject(project)}
              isInactive={false}
            />
          ))}
        </ProjectsContainer>
      </ProjectsSection>
      {/*<InactiveProjectsSection>
        <h2>Inactive Projects</h2>
        <ProjectsContainer>
          {inactiveProjects.map(project => (
            <ProjectCard
              key={project.id}
              project={project}
              configurations={project.configurations} // Pass configurations
              isSelected={selectedProject && selectedProject.id === project.id}
              onClick={() => setSelectedProject(project)}
              isInactive={true}
            />
          ))}
        </ProjectsContainer>
      </InactiveProjectsSection> */}
    </DashboardContainer>
  );
};

export default Dashboard;
