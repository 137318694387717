import React, { useEffect, useState } from 'react';
import { TableContainer, ErrorText, LoadingText } from '../styles/BillOfMaterialsStyles'; // Update styles for BillOfMaterials if needed
import SearchBar from './SearchBar';
import DimensionSearchBar from './DimensionSearchBar';
import BomTableBody from './BomTableBody'; // Use the new BomTableBody component
import { useApiClient } from '../hooks/useApiClient';
import { useParams, useLocation } from 'react-router-dom';

const BillOfMaterials = () => {
  const { projectId, configurationId } = useParams(); // Retrieve both projectId and configurationId from URL
  const location = useLocation();
  const { projectName } = location.state || {};  // Retrieve project name from location state

  const [assets, setAssets] = useState([]);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedChips, setSelectedChips] = useState({ name: true, keywords: true });

  // State for dimension search
  const [lengthQuery, setLengthQuery] = useState('');
  const [widthQuery, setWidthQuery] = useState('');
  const [heightQuery, setHeightQuery] = useState('');
  const [comparison, setComparison] = useState('exact');

  const apiClient = useApiClient();

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const response = await apiClient.get(`/assets/${projectId}/${configurationId}/bom/`);
        console.log(response);
        
        const assetsData = response.data.assets || [];
        setAssets(assetsData);
        setFilteredAssets(assetsData); // Initialize with unfiltered data
        setLoading(false);
      } catch (error) {
        console.error("Error fetching materials data:", error);
        setError('Failed to load materials. Please try again later.');
        setLoading(false);
      }
    };

    fetchAssets();
  }, [projectId, configurationId]);

    // Calculate total cost
    const totalCost = assets.reduce((sum, asset) => sum + (asset.cost * asset.count || 0), 0);

  // Search and filter functionality
  useEffect(() => {
    if (assets.length > 0) {
      applySearch(searchQuery, selectedChips);
    }
  }, [searchQuery, lengthQuery, widthQuery, heightQuery, comparison, selectedChips, assets]);

  const applySearch = (query, chipsState = selectedChips) => {
    console.log("Applying search with query:", query);
    console.log("Dimension queries - Length:", lengthQuery, "Width:", widthQuery, "Height:", heightQuery);
  
    let filtered = assets;
  
    // Reset to show all data if no filters are set
    if (!query && !lengthQuery && !widthQuery && !heightQuery) {
      console.log("No filters applied; displaying all assets.");
      setFilteredAssets(assets);
      return;
    }
  
    // Filter by dimensions first, and then by other search criteria
    filtered = assets.filter(item => filterByDimensions(item));
    console.log("After dimension filtering:", filtered);
  
    if (query) {
      filtered = filtered.filter(item => {
        return (
          (chipsState.name && item.name && item.name.toLowerCase().includes(query)) ||
          (chipsState.keywords && item.tags && item.tags.some(tag => tag.toLowerCase().includes(query)))
        );
      });
    }
  
    console.log("After applying search query:", filtered);
    setFilteredAssets(filtered);
  };
  
  const filterByDimensions = (item) => {
    if (!item.dimensions || typeof item.dimensions !== 'string') {
      console.log("Invalid or missing dimensions:", item.dimensions);
      return true; // Skip filtering if dimensions are invalid or not a string
    }
  
    // Parse dimensions string format "{length,width,height}"
    const [length, width, height] = item.dimensions
      .replace(/{|}/g, '') // Remove curly braces
      .split(',')
      .map(dim => parseFloat(dim.trim())); // Convert each part to a number
  
    console.log("Parsed dimensions - Length:", length, "Width:", width, "Height:", height);
  
    // Validate parsed dimensions using filters
    const lengthValid = applyDimensionFilter(length, lengthQuery, comparison);
    const widthValid = applyDimensionFilter(width, widthQuery, comparison);
    const heightValid = applyDimensionFilter(height, heightQuery, comparison);
  
    console.log("Dimension validation - Length:", lengthValid, "Width:", widthValid, "Height:", heightValid);
    return lengthValid && widthValid && heightValid;
  };
  
  const applyDimensionFilter = (dimensionValue, queryValue, comparison) => {
    console.log("Applying dimension filter:", {
      dimensionValue,
      queryValue,
      comparison
    });
  
    if (isNaN(dimensionValue)) {
      console.log("Invalid dimension value, skipping:", dimensionValue);
      return true; // Skip if dimension parsing failed
    }
    
    if (!queryValue) return true;
    const parsedQuery = parseFloat(queryValue);
    if (isNaN(parsedQuery)) return false;
  
    switch (comparison) {
      case 'bigger than':
        return dimensionValue > parsedQuery;
      case 'lower than':
        return dimensionValue < parsedQuery;
      case 'exact':
      default:
        return dimensionValue === parsedQuery;
    }
  };

  return (
    <TableContainer>
      <h1>Bill of Materials for {projectName}</h1>
        {/* Total Cost Display */}
      <h2>Total Cost: ${totalCost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h2>

      {/* Search Bar Component */}
      <SearchBar
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        selectedChips={selectedChips}
        setSelectedChips={setSelectedChips}
      />

      {/* Dimension Search Bar Component */}
      <DimensionSearchBar
        lengthQuery={lengthQuery}
        widthQuery={widthQuery}
        heightQuery={heightQuery}
        comparison={comparison}
        setLengthQuery={setLengthQuery}
        setWidthQuery={setWidthQuery}
        setHeightQuery={setHeightQuery}
        setComparison={setComparison}
      />

      {/* Loading and Error Messages */}
      {loading && <LoadingText>Loading...</LoadingText>}
      {error && <ErrorText>{error}</ErrorText>}

      {/* Table Body with Material Rows */}
      <BomTableBody data={filteredAssets} /> {/* Use filteredMaterials data directly */}
    </TableContainer>
  );
};

export default BillOfMaterials;
