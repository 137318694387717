import React, { useState, useEffect } from 'react';
import { EditableInput, TableRow, TableData, KeywordsData, Table } from '../styles/AssetsTableBodyStyles'; 
import { ImageGallery, ImageThumbnail, FullScreenImage, LimitedWidthSpan, LimitedWidthSmallColumn, LimitedWidthMediumColumn } from '../styles/AssetRowStyles';


const AssetRow = ({ asset, index, setCombinedData, setEditedMaterialSetIds, isEditable }) => {
  const [fullscreenImage, setFullscreenImage] = useState(null);  // Track the image to display in fullscreen
  const [localKeywords, setLocalKeywords] = useState(asset.tags || []);  // Local state for the keywords
  const [localCost, setLocalCost] = useState(asset.cost || '');  // Local state for cost
  const [localProducer, setLocalProducer] = useState(asset.producer || '');  // Local state for producer
  const [localDesigner, setLocalDesigner] = useState(asset.designer || '');  // Local state for designer
  const [localDescription, setLocalDescription] = useState(asset.description || '');  // Local state for description
  const [localPromptDescription, setLocalPromptDescription] = useState(asset.prompt_description || '');  // Local state for description
  const [localCoverImage, setLocalCoverImage] = useState(asset.cover_image || '');  // Local state for cover image
  const [localName, setLocalName] = useState(asset.materialSetName || '');  // Local state for name
  const [localPageLink, setLocalPageLink] = useState(asset.page_link || '');  // Local state for name
  const [localFloorVector, setLocalFloorVector] = useState(
    `${asset.floor_vector?.x ?? 0}, ${asset.floor_vector?.y ?? 0}, ${asset.floor_vector?.z ?? 0}`
  );
  
  // Extract dimensions (assuming dimensions is in the format "{length,width,height}")
  const [length, width, height] = asset.dimensions.replace(/{|}/g, '').split(',').map(dim => dim.trim());

  
  
  // Logic to handle image click and open in fullscreen
  const handleImageClick = (imageUrl) => {
    setFullscreenImage(imageUrl);  // Set the clicked image as fullscreen image
  };

  // Logic to handle input changes and updates to combinedData
  const handleFieldChange = (field, newValue) => {
    setEditedMaterialSetIds(prev => new Set(prev).add(asset.materialSetId)); 

    // Update combinedData in parent
    setCombinedData(prevData => {
      const updatedData = [...prevData];
      updatedData[index] = { ...updatedData[index], [field]: newValue };
      return updatedData;
    });
  };

  useEffect(() => {
    console.log('Description for asset:', asset.assetName, asset.floor_vector);
    console.log('floor_vector:', localFloorVector);
    console.log('prompt_description:', localPromptDescription);
  }, [asset, localDescription]);  // Runs when the asset or localDescription changes
  

  const handleKeywordsChange = (newKeywords) => {
    setLocalKeywords(newKeywords);
    handleFieldChange('tags', newKeywords);
  };

  const handleCostChange = (e) => {
    setLocalCost(e.target.value);
    handleFieldChange('cost', parseFloat(e.target.value) || 0); 
  };

  const handleProducerChange = (e) => {
    setLocalProducer(e.target.value);
    handleFieldChange('producer', e.target.value);
  };

  const handleDesignerChange = (e) => {
    setLocalDesigner(e.target.value);
    handleFieldChange('designer', e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setLocalDescription(e.target.value);
    handleFieldChange('description', e.target.value);
  };

  
  const handlePromptDescriptionChange = (e) => {
    setLocalPromptDescription(e.target.value);
    handleFieldChange('prompt_description', e.target.value);
  };


  const handleCoverImageChange = (e) => {
    setLocalCoverImage(e.target.value);
    handleFieldChange('cover_image', e.target.value);
  };

  const handleNameChange = (e) => {
    setLocalName(e.target.value);
    handleFieldChange('materialSetName', e.target.value);
  };

  const handlePageLinkChange = (e) => {
    setLocalPageLink(e.target.value);
    handleFieldChange('page_link', e.target.value);
  };

  const handleFloorVectorChange = (e) => {
    // Update the input value as a string without parsing
    setLocalFloorVector(e.target.value);
  };
  
  const handleFloorVectorBlur = () => {
    if (typeof localFloorVector === 'string') {
      // Parse the input string (e.g., "0, 1, 0") into an object { x: 0, y: 1, z: 0 }
      const [x, y, z] = localFloorVector.split(',').map(Number);
      const parsedFloorVector = { x: x || 0, y: y || 0, z: z || 0 };
  
      // Update the parsed floor vector in the parent state
      handleFieldChange('floor_vector', parsedFloorVector);
    }
  };
  
  

  return (
    <>
      <TableRow>
      <TableData>
        <ImageGallery>
          {asset.images && asset.images.length > 0 ? (
            asset.images.map((imageUrl, idx) => (
              <ImageThumbnail
                key={idx}
                src={imageUrl.image_url}
                alt={`Image ${idx + 1} of ${asset.assetName}`}
                onClick={() => handleImageClick(imageUrl.image_url)}  // Open the clicked image in fullscreen
              />
            ))
          ) : (
            <p>No images available</p>  // Display this if there are no images
          )}
        </ImageGallery>
        
      </TableData>
      <TableData>{asset.materialSetId}</TableData>
      
      <TableData>
      {isEditable ? (
        <EditableInput
          type="text"
          value={localName}
          onChange={handleNameChange}
          placeholder="Name"
        /> ): (
          <LimitedWidthMediumColumn>{localName}</LimitedWidthMediumColumn>
        )}
      </TableData>
      <TableData>
        {isEditable ? (
          <EditableInput
            type="text"
            value={localFloorVector}
            onChange={handleFloorVectorChange}
            onBlur={handleFloorVectorBlur}
            placeholder="Floor Vector (x, y, z)"
          />
        ) : (
          <LimitedWidthSmallColumn>
            {`x: ${asset.floor_vector?.x ?? 0}, y: ${asset.floor_vector?.y ?? 0}, z: ${asset.floor_vector?.z ?? 0}`}
          </LimitedWidthSmallColumn>
        )}
      </TableData>


      <TableData>
      {isEditable ? (
        <EditableInput
          type="text"
          value={localPromptDescription}
          onChange={handlePromptDescriptionChange}
          placeholder="Prompt Description"
        />):(<LimitedWidthSpan>{localPromptDescription}</LimitedWidthSpan>)}
      </TableData>
      <TableData>
      {isEditable ? (
        <EditableInput
          type="text"
          value={localDescription}
          onChange={handleDescriptionChange}
          placeholder="Description"
        />):(<LimitedWidthSpan>{localDescription}</LimitedWidthSpan>)}
      </TableData>
      <KeywordsData>
      {isEditable ? (
        <EditableInput
          value={localKeywords.join(', ')}
          onChange={(e) => handleKeywordsChange(e.target.value.split(',').map(kw => kw.trim()))}
        /> ):(<LimitedWidthSpan>{localKeywords.join(', ')}</LimitedWidthSpan>)}
      </KeywordsData>
      <TableData>
        {isEditable ? (
          <>
            <span>$</span>
            <EditableInput
              type="number"
              value={localCost}
              onChange={handleCostChange}
              placeholder="Cost"
              step="0.01"  // Allow decimals
            />
          </>
        ) : (
          <LimitedWidthSmallColumn>
            {localCost ? `$${localCost}` : '-'}
          </LimitedWidthSmallColumn>
        )}
      </TableData>
      <TableData>
      {isEditable ? (<EditableInput
          type="text"
          value={localProducer}
          onChange={handleProducerChange}
          placeholder="Producer"
        />):(<LimitedWidthMediumColumn>{localProducer}</LimitedWidthMediumColumn>)}
      </TableData>
      <TableData>
      {isEditable ? (<EditableInput
          type="text"
          value={localDesigner}
          onChange={handleDesignerChange}
          placeholder="Designer"
        />):(<LimitedWidthMediumColumn>{localDesigner}</LimitedWidthMediumColumn>)}
      </TableData>
      <TableData>
      {isEditable ? (<EditableInput
          type="text"
          value={localPageLink}
          onChange={handlePageLinkChange}
          placeholder="Page Link"
        />):(<LimitedWidthMediumColumn>{localPageLink}</LimitedWidthMediumColumn>)}
      </TableData>
      <TableData>
      {isEditable ? (<EditableInput
          type="text"
          value={localCoverImage}
          onChange={handleCoverImageChange}
          placeholder="Cover Image"
        />):(<LimitedWidthMediumColumn>{localCoverImage}</LimitedWidthMediumColumn>)}
      </TableData>
      <TableData>{length || '-'}</TableData>
      <TableData>{width || '-'}</TableData>
      <TableData>{height || '-'}</TableData>
    </TableRow>

      {/* Full Screen Image Modal */}
      {fullscreenImage && (
        <FullScreenImage src={fullscreenImage} alt="Fullscreen" onClick={() => setFullscreenImage(null)} />
      )}
    </>
  );
};

export default AssetRow;
