import React from 'react';
import { SearchBarContainer, SearchInput, Chip } from '../styles/SearchBarStyles'; // Updated styles for search bar

const SearchBar = ({ searchQuery, setSearchQuery, selectedChips, setSelectedChips }) => {
  // Handle search input
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);  // Update the search query in the parent state
  };

  // Handle chip toggle
  const handleChipToggle = (property) => {
    setSelectedChips(prevState => {
      const selectedChipCount = Object.values(prevState).filter(isSelected => isSelected).length;

      if (selectedChipCount === 1 && prevState[property]) {
        return prevState; // Prevent deselecting the last active chip
      }

      const newState = {
        ...prevState,
        [property]: !prevState[property]  // Toggle the chip's selected state
      };

      return newState;
    });
  };

  return (
    <SearchBarContainer>
      <SearchInput
        type="text"
        placeholder="Search by selected properties..."
        value={searchQuery}
        onChange={handleSearch}  // Use the internal handleSearch
      />
      <Chip selected={selectedChips.name} onClick={() => handleChipToggle('name')}>
        Name
      </Chip>
      <Chip selected={selectedChips.keywords} onClick={() => handleChipToggle('keywords')}>
        Keywords
      </Chip>
    </SearchBarContainer>
  );
};

export default SearchBar;
