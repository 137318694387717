import React, { useState } from 'react';
import { TableRow, TableData } from '../styles/BomTableBodyStyles';
import { ImageThumbnail, FullScreenImage } from '../styles/BomAssetRowStyles';

const BomAssetRow = ({ asset }) => {
  const [fullscreenImage, setFullscreenImage] = useState(null); // Track the image to display in fullscreen

  // Extract dimensions in the same format as in AssetRow
  let length, width, height;
  if (asset.dimensions) {
    [length, width, height] = asset.dimensions
      .replace(/{|}/g, '') // Remove curly braces if present
      .split(',')
      .map(dim => dim.trim());
  } else {
    length = width = height = '-';
  }

  // Logic to handle image click and open in fullscreen
  const handleImageClick = (imageUrl) => {
    setFullscreenImage(imageUrl); // Set the clicked image as fullscreen image
  };

  return (
    <>
      <TableRow>
        <TableData>
            <ImageThumbnail
                src={asset.cover_image || `${process.env.PUBLIC_URL}/placeholder_small.jpg`} // Use cached placeholder image
                alt={`Cover for ${asset.name}`}
                onClick={() => handleImageClick(asset.cover_image || `${process.env.PUBLIC_URL}/placeholder_small.jpg`)} // Ensure placeholder opens if no cover image
            />
        </TableData>
        <TableData>{asset.count || '1'}</TableData>
        {/*<TableData>{asset.id}</TableData>*/}
        <TableData>{asset.name}</TableData>
        <TableData>{asset.tags?.join(', ') || '-'}</TableData>
        <TableData>${(asset.cost || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableData>
        <TableData>{asset.producer || '-'}</TableData>
        <TableData>{asset.designer || '-'}</TableData>
        <TableData>{asset.page_link || '-'}</TableData>
        <TableData>{length || '-'}</TableData>
        <TableData>{width || '-'}</TableData>
        <TableData>{height || '-'}</TableData>
      </TableRow>

      {/* Full Screen Image Modal */}
      {fullscreenImage && (
        <FullScreenImage
          src={fullscreenImage}
          alt="Fullscreen"
          onClick={() => setFullscreenImage(null)} // Close fullscreen on click
        />
      )}
    </>
  );
};

export default BomAssetRow;
