import React, { useState, useEffect } from 'react';
import { OverlayContainer, LoadingBarContainer, LoadingBarProgress, LoadingBarText, LoadingBarTitle} from '../styles/LoadingBarStyles';

// Predefined curve for smooth, non-linear progress over 60 seconds (in percentages)
const progressCurve = [
  { time: 0, progress: 0 },
  { time: 10, progress: 20 },
  { time: 20, progress: 35 },
  { time: 30, progress: 45 },
  { time: 40, progress: 60 },
  { time: 50, progress: 75 },
  { time: 55, progress: 85 },
  { time: 58, progress: 92 },
  { time: 60, progress: 99 }
];

const LoadingBar = ({ isLoading }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!isLoading) return;

    const startTime = Date.now();
    const duration = 60000; // 60 seconds in milliseconds

    const intervalId = setInterval(() => {
      const elapsed = (Date.now() - startTime) / 1000; // Convert elapsed time to seconds

      if (elapsed >= 60) {
        setProgress(99);
        clearInterval(intervalId);
        return;
      }

      // Calculate smooth progress using the predefined curve
      const { progress: currentProgress } = progressCurve.reduce((prev, curr) => {
        return elapsed >= curr.time ? curr : prev;
      });
      
      // Smooth transition between points using interpolation
      const nextIndex = progressCurve.findIndex(point => point.time > elapsed);
      if (nextIndex === -1) {
        setProgress(currentProgress);
      } else {
        const nextPoint = progressCurve[nextIndex];
        const prevPoint = progressCurve[nextIndex - 1];
        const progressDiff = nextPoint.progress - prevPoint.progress;
        const timeDiff = nextPoint.time - prevPoint.time;
        const progressIncrement = (elapsed - prevPoint.time) / timeDiff * progressDiff;
        
        setProgress(prevPoint.progress + progressIncrement);
      }

    }, 100); // Update every 100 ms for smooth animation

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [isLoading]);

  return (
    <OverlayContainer>
      <LoadingBarTitle>
        Preparing Your Experience...
      </LoadingBarTitle>
      <LoadingBarContainer>
        <LoadingBarProgress style={{ width: `${progress}%` }} />
        <LoadingBarText>{Math.floor(progress)}%</LoadingBarText>
      </LoadingBarContainer>
    </OverlayContainer>
  );
  
  
};

export default LoadingBar;
