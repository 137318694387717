import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Image, Location, Title, Button, ButtonContainer } from '../styles/ProjectCardStyles';

const ProjectCard = ({ project, configurations, isSelected, onClick, isInactive }) => {
  const navigate = useNavigate();

  // Initialize configId only once, using the first configuration if available
  const configId = configurations && configurations.length > 0 ? configurations[0].id : null;

  const handleEnterClick = () => {
    const token = localStorage.getItem('access_token');

    if (project.active && token && configId) {
      const url = `${window.location.origin}/streaming?token=${encodeURIComponent(token)}&projectId=${encodeURIComponent(project.id)}&configId=${encodeURIComponent(configId)}`;
      window.open(url, '_blank');
    }
  };

  const handleFurnitureListClick = () => {
    if (configId) {
      navigate(`/projects/${project.id}/${configId}/bill-of-materials`, {
        state: { projectName: project.project_name },
      });    }
  };

  const handleMediaClick = () => {
    if (configId) {
      navigate(`/projects/${project.id}/${configId}/media`, {
        state: { projectName: project.project_name },
      });    }
  };


  return (
    <Card isSelected={isSelected} onClick={onClick}>
      <Image 
        src={project.cover_image || 'https://via.placeholder.com/200'} 
        alt={project.project_name} 
        isInactive={isInactive}
      />
      <Title>{project.project_name}</Title>
      <Location>{project.project_address}</Location>
      <ButtonContainer>
        <Button onClick={handleEnterClick}>Start Experience</Button>
        <Button onClick={handleFurnitureListClick}>Furniture List</Button>
        <Button onClick={handleMediaClick}>View Photos</Button>
      </ButtonContainer>
    </Card>
  );
};

export default ProjectCard;
