//createProjectStyles.js
import styled from 'styled-components';

export const CreateProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #f0f2f5;
  position: relative;
  margin-right: 20px;
  height: 100vh; 
  overflow-y: auto; 
  padding-bottom: 60px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  width: 80%;
`;

export const Title = styled.h1`
  font-size: 24px;
  color: #333333;
  margin: 0;
`;

export const CardsContainer = styled.div`
  display: flex;
  justify-content: center; /* Center the cards */
  padding: 100px;
  gap: 40px; /* Reduce gap to bring cards closer together */
`;


export const ProjectCard = styled.div`
  background: #ffffff;
  border-radius: 10px;
  width: 400px; /* Increased width for larger cards */
  height: auto; /* Increased width for larger cards */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;

  img {
    width: 100%;
    height: 300px; /* Increased height for larger images */
    object-fit: cover;
    border-radius: 10px 10px 0 0px;
  }

  h2 {
    font-size: 24px; /* Increased font size */
    color: #333333;
    margin: 30px 20px;
  }

  p {
    font-size: 16px; /* Increased font size */
    color: #888888;
    margin: 20px 20px 40px 20px;
  }
`;

export const StepIndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left:0;
  width: 100%;
  background-color: #f0f2f5;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
`;

export const StepIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* This ensures it stays within the content area */
  max-width: 1200px; /* Optional: set a max-width if desired */
  margin: 0 auto; /* Centers it within the parent container */
  padding: 20px;
`;

export const Step = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ isActive }) => (isActive ? '#007bff' : '#e0e0e0')};
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  font-size: 16px;
`;

export const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  background-color: ${({ disabled }) => (disabled ? '#d3d3d3' : '#007bff')};
  color: #ffffff;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  margin-right: 30px;
  margin-left: 30px;

  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#d3d3d3' : '#0056b3')};
  }
`;