import styled from 'styled-components';

// Wrapper for the table that controls vertical scrolling
export const TableContainer = styled.div`
  margin: 20px;
  padding: 0;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: calc(80vh - 100px);  // Adjust based on your available viewport space
  overflow-y: auto;  // Enable vertical scrolling
  position: relative;  // Ensure horizontal scrollbar is positioned correctly
`;

// Separate wrapper for horizontal scrolling, sticky at the bottom
export const HorizontalScrollContainer = styled.div`
  overflow-x: auto;  // Enable horizontal scrolling
  width: 100%;  // Make sure it takes full width of the container
  position: sticky;  // Stick the horizontal scrollbar at the bottom
  bottom: 0;
  background-color: #fff;  // Match background color for better visibility
  z-index: 10;  // Ensure it's on top of other elements
`;

// The actual table that will be horizontally scrollable
export const Table = styled.table`
  min-width: 2500px;  // Set a large enough width to enable horizontal scrolling
  border-collapse: collapse;
`;

export const TableHead = styled.thead`
  background-color: #f5f5f5;
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

export const TableHeader = styled.th`
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  white-space: nowrap;
`;

export const TableBody = styled.tbody``;

export const TableData = styled.td`
  padding: 12px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  width: 1%;
`;

export const KeywordsHeader = styled(TableHeader)`
  width: auto;
`;

export const KeywordsData = styled(TableData)`
  padding-right: 40px;
  min-width: 200px;
`;

export const EditableInput = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
`;

export const ButtonRow = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;
