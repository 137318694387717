import styled from 'styled-components';

export const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;

export const SearchInput = styled.input`
  padding: 10px;
  max-width: 500px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ddd;
  flex-grow: 1;
`;

export const Chip = styled.div`
  padding: 8px 12px;
  background-color: ${props => (props.selected ? '#007bff' : '#f0f0f0')};
  color: ${props => (props.selected ? '#fff' : '#000')};
  border-radius: 20px;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${props => (props.selected ? '#0056b3' : '#e0e0e0')};
  }
`;
