import styled from 'styled-components';

export const ImageGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-width: 100%;  // Adjust the width to handle multiple images dynamically
`;

export const ImageThumbnail = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
`;

export const FullScreenImage = styled.img`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
  cursor: pointer;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8);
  border: 2px solid white;
`;

export const LimitedWidthSpan = styled.span`
  display: inline-block;
  max-width: 600px; /* Adjust the width as needed */
  min-width: 400px; /* Adjust the width as needed */
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  word-wrap: break-word;
`;

export const LimitedWidthSmallColumn = styled.span`
  display: inline-block;
  max-width: 200px; /* Adjust the width as needed */
  min-width: 50px; /* Adjust the width as needed */
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  word-wrap: break-word;
`;

export const LimitedWidthMediumColumn = styled.span`
  display: inline-block;
  max-width: 300px; /* Adjust the width as needed */
  min-width: 20px; /* Adjust the width as needed */
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  word-wrap: break-word;
`;