import React, { useState } from 'react';
import { AuthContainer, AuthCard, AuthTitle, AuthInput, AuthButton, AuthLink } from '../styles/AuthFormStyles';
import { Link, useNavigate } from 'react-router-dom';
import { useApiClient } from '../hooks/useApiClient';  // Import the custom hook


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const apiClient = useApiClient();  // Use the custom hook


  const handleLogin = async () => {
    try {
      const response = await apiClient.post('/auth/login', {
        email,
        password,
      });
  
      const { access_token } = response.data;
      localStorage.setItem('access_token', access_token);
      navigate('/projects');
    } catch (error) {
      if (error.response && error.response.status === 403) {
        alert('Your account is not verified. Please check your email.');
      } else {
        console.error('Error logging in:', error);
        alert('Invalid credentials. Please try again.');
      }
    }
  };
  

  return (
    <AuthContainer>
      <AuthCard>
        <AuthTitle>Login</AuthTitle>
        <AuthInput type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <AuthInput type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <AuthButton onClick={handleLogin}>Login</AuthButton>
        <AuthLink>
          Don't have an account? <Link to="/register">Register</Link>
        </AuthLink>
      </AuthCard>
    </AuthContainer>
  );
}

export default Login;
