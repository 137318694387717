import React, { useState, useEffect } from 'react';
import { SidebarContainer, Logo, NavItem, NavItemContent, BottomNavContainer } from '../styles/SidebarStyles';
import { useNavigate } from 'react-router-dom';
import { useApiClient } from '../hooks/useApiClient'; // Custom hook for API requests

const Sidebar = ({ setActiveSection, setIsLoggedIn, activeSection }) => {
  const [profileImage, setProfileImage] = useState(null); // State for storing the profile image
  const navigate = useNavigate();
  const apiClient = useApiClient(); // Use the API client hook

  useEffect(() => {
    // Fetch the user profile image
    const fetchProfileImage = async () => {
      try {
        const response = await apiClient.get('/user/');
        if (response.status === 200) {
          console.log('fetched profile image:', response.data);
          setProfileImage(response.data.profile_picture); // Assuming the API returns the profile image URL in `profile_picture`
        }
      } catch (error) {
        console.error('Error fetching profile image:', error);
      }
    };

    fetchProfileImage();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    setIsLoggedIn(false);
    navigate('/'); // Redirect to the home page after logout
  };

  return (
    <SidebarContainer>
      <Logo>
        <img
          src={profileImage || `${process.env.PUBLIC_URL}/logo.png`} // Use the profile image or fall back to the default logo
          alt="Profile"
        />
      </Logo>
      <NavItem
        onClick={() => setActiveSection('projects')}
        $active={activeSection === 'projects'} // Use the activeSection prop
      >
        <NavItemContent>
          <img src={`${process.env.PUBLIC_URL}/house_icon.png`} alt="icon" />
          Projects
        </NavItemContent>
      </NavItem>
      <NavItem
        onClick={() => setActiveSection('assets')}
        $active={activeSection === 'assets'} // Use the activeSection prop
      >
        <NavItemContent>
          <img src={`${process.env.PUBLIC_URL}/objects_icon.png`} alt="icon" />
          Assets
        </NavItemContent>
      </NavItem>
      <NavItem
        onClick={() => setActiveSection('analytics')}
        $inactive={true}
        $active={activeSection === 'analytics'} // Use the activeSection prop
      >
        <NavItemContent $inactive={true}>
          <img src={`${process.env.PUBLIC_URL}/analytics_icon.png`} alt="icon" />
          Analytics
        </NavItemContent>
      </NavItem>
      <NavItem
        onClick={() => setActiveSection('integrations')}
        $inactive={true}
        $active={activeSection === 'integrations'}
      >
        <NavItemContent $inactive={true}>
          <img src={`${process.env.PUBLIC_URL}/integrations_icon.png`} alt="icon" />
          Integrations
        </NavItemContent>
      </NavItem>

      <BottomNavContainer>
        <NavItem
          onClick={() => setActiveSection('help')}
          $active={activeSection === 'help'} // Use the activeSection prop
        >
          <NavItemContent>
            <img src={`${process.env.PUBLIC_URL}/customer_service_icon.png`} alt="icon" />
            Help
          </NavItemContent>
        </NavItem>
        <NavItem
          onClick={() => setActiveSection('account')}
          $active={activeSection === 'account'} // Use the activeSection prop
        >
          <NavItemContent>
            <img src={`${process.env.PUBLIC_URL}/user_pic_icon.png`} alt="icon" />
            Account
          </NavItemContent>
        </NavItem>
        <NavItem onClick={handleLogout}>
          <NavItemContent>
            <img src={`${process.env.PUBLIC_URL}/logout_icon.png`} alt="icon" />
            Logout
          </NavItemContent>
        </NavItem>
      </BottomNavContainer>
    </SidebarContainer>
  );
};

export default Sidebar;
