import styled from 'styled-components';

export const TableContainer = styled.div`
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  overflow-y: auto;
  position: relative; /* For positioning the fullscreen image */
`;

export const CustomButton = styled.button`
  padding: 10px 20px;
  margin-bottom: 10px;
  background-color: red;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 8px;

  &:hover {
    background-color: #0056b3;
  }
`;

export const ErrorText = styled.p`
  color: red;
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
`;

export const LoadingText = styled.p`
  color: #007bff;
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
`;

export const UpdateLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6); /* Dark overlay covering the entire screen */
  font-size: 18px;
  color: white;
  z-index: 1000;
`;

