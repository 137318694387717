import styled from 'styled-components';

export const MenuContainer = styled.div`
  position: absolute;
  top: 40px;
  right: 8px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 100;
  overflow: hidden;
  width: 120px;
  padding: 4px 0;
`;

export const MenuButton = styled.button`
  display: block;
  width: 100%;
  padding: 8px 12px;
  background-color: transparent;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f0f0f0;
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
