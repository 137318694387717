import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100vh;
  background-color: #f0f2f5;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;  // Allow both vertical and horizontal scrolling
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;  // Allow scrolling inside this container (both directions)
`;

export const ProjectDetailsWrapper = styled.div`
  width: 270px;
  padding: 16px;
  border-left: 1px solid #e0e0e0;
  background-color: #ffffff;
  flex-shrink: 0;
`;

export const DashboardWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;  // Allow vertical scrolling here
  overflow-x: hidden;  // Disable horizontal scrolling at this level
`;

export const Card = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

export const Title = styled.h1`
  color: #333333;
  margin-bottom: 20px;
`;

export const Button = styled.button`
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
  &:hover {
    background-color: #0056b3;
  }
`;
