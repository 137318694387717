import React, { useEffect, useState } from 'react';
import { TableContainer, ErrorText, LoadingText, CustomButton, UpdateLoader } from '../styles/AssetsTableStyles'; // Common styles like error, loading, and buttons
import SearchBar from './SearchBar';
import DimensionSearchBar from './DimensionSearchBar';
import AssetsTableBody from './AssetsTableBody';
import { useApiClient } from '../hooks/useApiClient';
import { jwtDecode } from 'jwt-decode';

const AssetsTable = () => {
  const [assets, setAssets] = useState([]);       // Store all assets
  const [materialSets, setMaterialSets] = useState([]);       // Store all material sets
  const [combinedData, setCombinedData] = useState([]);  // Store combined data
  const [filteredCombinedData, setFilteredCombinedData] = useState([]);  // Store filtered data (after applying search/filter)
  const [loading, setLoading] = useState(true);   
  const [error, setError] = useState(null);       
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [editedAssetIds, setEditedAssetIds] = useState(new Set());
  const [editedMaterialSetIds, setEditedMaterialSetIds] = useState(new Set());
  const [searchQuery, setSearchQuery] = useState('');       // Search query
  const [updateLoading, setUpdateLoading] = useState(false);
  const [selectedChips, setSelectedChips] = useState({
    name: true,
    keywords: true
  });

  // State for dimension search
  const [lengthQuery, setLengthQuery] = useState('');
  const [widthQuery, setWidthQuery] = useState('');
  const [heightQuery, setHeightQuery] = useState('');
  const [comparison, setComparison] = useState('exact');

  const apiClient = useApiClient();
  // Get uid from token and check if it's in the allowed list
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    console.log("Token from localStorage:", token); // Log the token itself
  
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
  
        const userUid = decodedToken?.sub?.uid;
  
        const allowedUids = ["a85c6163-69b2-497b-9f62-8d696efa72df", "3ca5bd28-8fd8-4c27-b0a3-d2ceb5d26b97", "626bc885-31a3-44f7-a508-c0d96e151087"];
        const isUserAllowed = allowedUids.includes(userUid);
  
        setIsEditable(isUserAllowed);
      } catch (error) {
        console.error("Error decoding token:", error); // Catch any errors during decoding
      }
    } else {
      console.warn("No token found"); // Log if no token exists
    }
  }, []);

  // Fetch assets and material sets from cache or API
  useEffect(() => {
    const fetchAssetsAndMaterialSets = async () => {
      try {
        const cachedAssets = localStorage.getItem('assets');
        const cachedMaterialSets = localStorage.getItem('material_sets');
        let parsedAssets, parsedMaterialSets;

        const response = await apiClient.get('/assets/');
          parsedAssets = reduceAssetData(response.data.assets);
          parsedMaterialSets = reduceMaterialSets(response.data.material_sets);
          localStorage.setItem('assets', JSON.stringify(parsedAssets));
          localStorage.setItem('material_sets', JSON.stringify(parsedMaterialSets));

        /*if (cachedAssets && cachedMaterialSets) {
          parsedAssets = JSON.parse(cachedAssets);
          parsedMaterialSets = JSON.parse(cachedMaterialSets);
        } else {
          const response = await apiClient.get('/assets/');
          parsedAssets = reduceAssetData(response.data.assets);
          parsedMaterialSets = reduceMaterialSets(response.data.material_sets);
          localStorage.setItem('assets', JSON.stringify(parsedAssets));
          localStorage.setItem('material_sets', JSON.stringify(parsedMaterialSets));
        } */

        // Combine assets and material sets into a single data structure
        const combinedData = combineMaterialSetsAndAssets(parsedMaterialSets, parsedAssets);
        
        setAssets(parsedAssets);
        setMaterialSets(parsedMaterialSets);
        setCombinedData(combinedData);  // Use the combined structure for rendering
        setFilteredCombinedData(combinedData);  // Initially, all data is displayed
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError('Failed to load data. Please try again later.');
        setLoading(false);
      }
    };

    fetchAssetsAndMaterialSets();
  }, []);

  // Only apply search/filter when there's search input or dimension queries and data is available
  useEffect(() => {
    if (combinedData.length > 0) {
      console.log("Combined data loaded. Applying search/filter...");
      applySearch(searchQuery, selectedChips);  // Always apply the search when chips change
    } else {
      console.log("Data not yet loaded, skipping search/filter.");
    }
  }, [searchQuery, lengthQuery, widthQuery, heightQuery, comparison, selectedChips, combinedData]);

  const combineMaterialSetsAndAssets = (materialSets, assets) => {
    const combined = materialSets.map(materialSet => {
      // Ensure associated_object_id exists
      const associatedObjectId = materialSet.associated_object_id;
  
      if (!associatedObjectId) {
        console.warn(`Material set with ID ${materialSet.id} has no associated_object_id.`);
        return null;  // Skip if associated_object_id is missing
      }
  
      // Find the corresponding asset
      const associatedAsset = assets.find(asset => asset.id === associatedObjectId);
  
      if (!associatedAsset) {
        console.warn(`No asset found for material set with associated_object_id: ${associatedObjectId}`);
        return null;  // Skip if no asset is found
      }
  
      return {
        materialSetId: materialSet.id,
        dimensions: associatedAsset.dimensions || '',  // Get dimensions from the asset
        tags: materialSet.tags,  // Use tags from the material set
        images: associatedAsset.images || [],  // Get images from the asset
        materialSetName: materialSet.name,
        assetId: associatedAsset.id,
        assetName: associatedAsset.name,
        cost: materialSet.cost,
        description: materialSet.description,
        prompt_description: materialSet.prompt_description,
        producer: associatedAsset.producer,
        designer: associatedAsset.designer,
        cover_image: materialSet.cover_image,
        page_link: materialSet.page_link,
        floor_vector: associatedAsset.floor_vector
      };
    }).filter(Boolean);  // Filter out any null entries (in case of missing assets)
  
    // Sort by materialSetId in increasing order
    return combined.sort((a, b) => a.materialSetId - b.materialSetId);
  };
  
  

  // Reduce assets for caching
  const reduceAssetData = (assets) => {
    return assets.map(asset => ({
      id: asset.id,
      name: asset.name,
      keywords: asset.keywords,
      images: asset.images,
      designer: asset.designer || '',
      producer: asset.producer || '',
      dimensions: asset.dimensions || '',
      floor_vector: asset.floor_vector
    }));
  };

  // Reduce material sets for caching
  const reduceMaterialSets = (materialSets) => {
    return materialSets.map(materialSet => ({
      id: materialSet.id,
      name: materialSet.name,
      tags: materialSet.tags,
      associated_object_id: materialSet.associated_object_id,
      cost: materialSet.cost,
      cover_image: materialSet.cover_image,
      description: materialSet.description,
      prompt_description: materialSet.prompt_description,
      page_link: materialSet.page_link || ''
    }));
  };

  const handleRefresh = async () => {
    console.log("Refreshing data by flushing cache and querying the server...");
    localStorage.removeItem('assets');  // Remove cached assets
    localStorage.removeItem('material_sets');  // Remove cached material sets
    setLoading(true);  // Show loading indicator
  
    try {
      const response = await apiClient.get('/assets/');  // Fetch assets and material sets again from server
      const reducedAssets = reduceAssetData(response.data.assets);
      const reducedMaterialSets = reduceMaterialSets(response.data.material_sets);
      localStorage.setItem('assets', JSON.stringify(reducedAssets));  // Cache the fresh assets
      localStorage.setItem('material_sets', JSON.stringify(reducedMaterialSets));  // Cache the fresh material sets
      setAssets(reducedAssets);
      setMaterialSets(reducedMaterialSets);
      const combinedData = combineMaterialSetsAndAssets(reducedMaterialSets, reducedAssets);
      setCombinedData(combinedData);  // Update combined data
  
      // Immediately apply the search/filter logic after refresh
      applySearch(searchQuery, selectedChips, combinedData);
  
      console.log("Assets and material sets refreshed and cache updated.");
      window.location.reload();  // Trigger a full page reload
    } catch (error) {
      console.error("Error refreshing data:", error);
      setError('Failed to refresh data. Please try again later.');
      setLoading(false);
    }
  };
  

  const handleUpdate = async () => {
    setUpdateLoading(true);
    try {
      const materialSetsToUpdate = combinedData.filter(item => editedMaterialSetIds.has(item.materialSetId));  // Only update material sets that were edited
  
      if (materialSetsToUpdate.length === 0) {
        alert('No changes to update.');
        setUpdateLoading(false);
        return;
      }
  
      await Promise.all(
        materialSetsToUpdate.map(item => {
          console.log('Updating item with ID:', item.materialSetId);
          console.log('floor_vector:', item.floor_vector);
  
          return apiClient.put(`/assets/${item.materialSetId}`, { 
            tags: item.tags, 
            cost: item.cost, 
            description: item.description,
            prompt_description: item.prompt_description,
            page_link: item.page_link,
            producer: item.producer,
            designer: item.designer,
            name: item.materialSetName,
            cover_image: item.cover_image,
            floor_vector:item.floor_vector
          });
        })
      );
      alert('Edited material sets updated successfully!');
      setEditedMaterialSetIds(new Set());  // Reset the edited material sets tracker
    } catch (error) {
      alert('Failed to update material sets. Please try again later.');
    } finally {
      setUpdateLoading(false);
    }
  };

  // Apply search and filters
  const applySearch = (query, chipsState = selectedChips) => {
    let filtered = combinedData;

    if (!query && !lengthQuery && !widthQuery && !heightQuery) {
      setFilteredCombinedData(combinedData);  // Reset to show all data
      return;
    }

    if (!query) {
      filtered = combinedData.filter(item => filterByDimensions(item));
      setFilteredCombinedData(filtered);
      return;
    }

    filtered = combinedData.filter(item => {
      return (
        (chipsState.name && item.materialSetName && item.materialSetName.toLowerCase().includes(query)) ||
        (chipsState.assetName && item.assetName && item.assetName.toLowerCase().includes(query)) ||
        (chipsState.keywords && item.tags && item.tags.some(tag => tag.toLowerCase().includes(query))) ||
        (chipsState.dimensions && filterByDimensions(item))
      );
    });

    setFilteredCombinedData(filtered);
  };

  const filterByDimensions = (item) => {
    if (!item.dimensions) return true;

    const [length, width, height] = item.dimensions.replace(/{|}/g, '').split(',').map(dim => parseFloat(dim.trim()));
    const lengthValid = applyDimensionFilter(length, lengthQuery, comparison);
    const widthValid = applyDimensionFilter(width, widthQuery, comparison);
    const heightValid = applyDimensionFilter(height, heightQuery, comparison);

    return lengthValid && widthValid && heightValid;
  };

  const applyDimensionFilter = (dimensionValue, queryValue, comparison) => {
    if (!queryValue) return true;
    const parsedQuery = parseFloat(queryValue);
    if (isNaN(parsedQuery)) return false;

    switch (comparison) {
      case 'bigger than':
        return dimensionValue > parsedQuery;
      case 'lower than':
        return dimensionValue < parsedQuery;
      case 'exact':
      default:
        return dimensionValue === parsedQuery;
    }
  };

  const handleGenerateEmbeddings = async () => {
    try {
      await apiClient.post('/assets/embeddings/', {});
      alert('Embeddings generated successfully!');
      // Optionally, fetch assets again to update the UI with new embeddings data
    } catch (error) {
      alert('Failed to generate embeddings');
      console.error('Error generating embeddings:', error);
    }
  };

  return (
    <TableContainer>
      {/* Search Bar Component */}
      <SearchBar
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        selectedChips={selectedChips}
        setSelectedChips={setSelectedChips}  // Correct here
      />
      
      {/* Dimension Search Bar Component */}
      <DimensionSearchBar
        lengthQuery={lengthQuery}
        widthQuery={widthQuery}
        heightQuery={heightQuery}
        comparison={comparison}
        setLengthQuery={setLengthQuery}
        setWidthQuery={setWidthQuery}
        setHeightQuery={setHeightQuery}
        setComparison={setComparison}
      />
      
      {/* Custom Buttons */}
      
      {isEditable ? (<CustomButton onClick={handleUpdate}>Update</CustomButton>) : (null)}
      {isEditable ? (<CustomButton onClick={handleRefresh}>Refresh</CustomButton>) : (null)}
      {/*isEditable ? (<CustomButton onClick={handleGenerateEmbeddings}>Generate Embeddings</CustomButton>) : (null)*/}

      {/* Loading and Error Messages */}
      {loading && <LoadingText>Loading...</LoadingText>}
      {error && <ErrorText>{error}</ErrorText>}

      {/* Table Body with Asset Rows */}
      <AssetsTableBody
        combinedData={filteredCombinedData}
        setCombinedData={setCombinedData}  // Pass setCombinedData to update combined data when keywords change
        setEditedMaterialSetIds={setEditedMaterialSetIds}  // Track edited material sets
        handleImageClick={setFullscreenImage}
        isEditable={isEditable}
      />
      {updateLoading && <UpdateLoader>Updating, please wait...</UpdateLoader>}
    </TableContainer>
  );
};

export default AssetsTable;
