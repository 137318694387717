// PixelStreamingStyles.js
import styled from 'styled-components';

export const PixelStreamingContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
`;
export const CenteredLogo = styled.img`
  position: absolute;
  top: 8px; /* Adjust the distance from the top as needed */
  left: 50%;
  transform: translateX(-50%); /* Center the logo horizontally */
  max-width: 65px; /* Adjust the size as needed */
  z-index: 999999;
`;

export const TopBand = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: black;
  z-index: 999998; /* 1 less than the logo */
`;

export const LoadingScreen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #000;
  color: #fff;
  font-size: 18px;
`;
