import styled from 'styled-components';

export const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1); /* Solid black background */
  display: flex;
  flex-direction: column; /* Arrange items in a column */
  align-items: center;
  justify-content: center;
  padding: 50px;
  z-index: 9998;
`;

export const LoadingBarTitle = styled.p`
  color: white;
  font-size: 18px;
  margin-bottom: 20px; /* Space between text and loading bar */
`;

export const LoadingBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  max-width: 500px;
  height: 20px;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 10px;
  overflow: hidden;
`;

export const LoadingBarProgress = styled.div`
  height: 100%;
  background: linear-gradient(45deg, #D92650, #8800B5);
  transition: width 0.1s linear;
  align-self: flex-start;
`;


export const LoadingBarText = styled.p`
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px; /* Optional margin for better spacing */
`;
