import React from 'react';
import { DimensionSearchBarContainer, DimensionSearchInput, ComparisonDropdown } from '../styles/DimensionSearchBarStyles'; 

const DimensionSearchBar = ({
  lengthQuery,
  widthQuery,
  heightQuery,
  comparison,
  setLengthQuery,
  setWidthQuery,
  setHeightQuery,
  setComparison
}) => {
  // Handle dimension input changes
  const handleDimensionSearchChange = (e, dimension) => {
    const { value } = e.target;
    if (dimension === 'length') setLengthQuery(value);
    if (dimension === 'width') setWidthQuery(value);
    if (dimension === 'height') setHeightQuery(value);
    console.log(`Dimension search query changed: ${dimension} = ${value}`);
  };

  // Handle comparison change
  const handleComparisonChange = (e) => {
    setComparison(e.target.value);
    console.log("Comparison changed:", e.target.value);
  };

  return (
    <DimensionSearchBarContainer>
      <DimensionSearchInput
        type="text"
        placeholder="Length"
        value={lengthQuery}
        onChange={(e) => handleDimensionSearchChange(e, 'length')}
      />
      <DimensionSearchInput
        type="text"
        placeholder="Width"
        value={widthQuery}
        onChange={(e) => handleDimensionSearchChange(e, 'width')}
      />
      <DimensionSearchInput
        type="text"
        placeholder="Height"
        value={heightQuery}
        onChange={(e) => handleDimensionSearchChange(e, 'height')}
      />
      <ComparisonDropdown value={comparison} onChange={handleComparisonChange}>
        <option value="exact">Exact</option>
        <option value="bigger than">Bigger Than</option>
        <option value="lower than">Lower Than</option>
      </ComparisonDropdown>
    </DimensionSearchBarContainer>
  );
};

export default DimensionSearchBar;
