import styled from 'styled-components';

export const TemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

export const TemplateTitle = styled.h2`
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 500;
  color: #333;
`;

export const ProjectInput = styled.input`
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 300px;
  text-align: center;
`;

export const TemplateRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 20px;
`;

export const TemplateCard = styled.div`
  width: 350px;
  height: 200px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border: ${({ isSelected }) => (isSelected ? '3px solid #007bff' : '3px solid transparent')};
  transition: border-color 0.2s ease;

  &:hover {
    border-color: #007bff;
  }
`;

export const TemplateImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Dark alpha overlay */
`;

export const TemplateCardTitle = styled.h3`
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  color: #ffffff;
  font-size: 36px;
  font-weight: bold;
  margin: 0;
  text-align: center;
`;

export const TemplateDimension = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
`;

export const DimensionIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;

export const ErrorText = styled.p`
  color: red;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
`;
