import styled from 'styled-components';

export const DimensionSearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;

export const DimensionSearchInput = styled.input`
  padding: 8px;
  font-size: 14px;
  width: 100px;
  border-radius: 5px;
  border: 1px solid #ddd;
  text-align: center;

  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

export const ComparisonDropdown = styled.select`
  padding: 8px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;
