import React, { useState } from 'react';
import OptionsMenu from './OptionsMenu';
import { 
    CardContainer, 
    ScreenshotImage, 
    ScreenshotName, 
    OptionsButton, 
    Overlay, 
    OverlayImage, 
    CloseButton 
} from '../styles/ScreenshotCardStyles';


const ScreenshotCard = ({ screenshot, isMenuOpen, toggleMenu, deleteScreenshot }) => {
    const [showOptions, setShowOptions] = useState(false);
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);

    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };

    // Extract the filename from the URL to use as a name
    const screenshotName = screenshot.split('/').pop();

    const handleOverlayOpen = () => {
        setIsOverlayVisible(true);
    };

    const handleOverlayClose = () => {
        setIsOverlayVisible(false);
    };

    return (
        <>
            <CardContainer onClick={handleOverlayOpen}>
                <ScreenshotImage src={screenshot} alt={screenshotName} />
                <ScreenshotName>{screenshotName}</ScreenshotName>
                <OptionsButton onClick={(e) => { e.stopPropagation(); toggleMenu(); }}>⋮</OptionsButton>
                {isMenuOpen && (
                    <OptionsMenu onClose={toggleMenu} screenshot={screenshot} deleteScreenshot={deleteScreenshot} />
                )}
                {showOptions && (
                    <OptionsMenu
                        onClose={() => setShowOptions(false)}
                        screenshot={screenshot}
                    />
                )}
            </CardContainer>

            {isOverlayVisible && (
                <Overlay onClick={handleOverlayClose}>
                    <OverlayImage src={screenshot} alt={screenshotName} />
                    <CloseButton onClick={handleOverlayClose}>×</CloseButton>
                </Overlay>
            )}
        </>
    );
};

export default ScreenshotCard;
