import React from 'react';
import { MenuContainer, MenuButton } from '../styles/OptionsMenuStyles';

const OptionsMenu = ({ onClose, screenshot, deleteScreenshot }) => {
    // Extract the filename from the URL to use it as the download name
    const filename = screenshot.split('/').pop();

    const downloadScreenshot = () => {
        // Open a new tab with the image URL
        const newTab = window.open(screenshot, '_blank');
        
        // Wait until the new tab is loaded, then trigger the download and close it
        if (newTab) {
            newTab.onload = () => {
                newTab.document.title = filename; // Optional: set the title
                const a = newTab.document.createElement('a');
                a.href = screenshot;
                a.download = filename;
                a.click();

                // Close the tab after the download is triggered
                newTab.close();
            };
        }
        onClose();
    };

    return (
        <MenuContainer>
            <MenuButton onClick={downloadScreenshot}>Download</MenuButton>
            <MenuButton onClick={() => { deleteScreenshot(); onClose(); }}>Delete</MenuButton>
        </MenuContainer>
    );
};

export default OptionsMenu;
