import React from 'react';
import { TableContainer, HorizontalScrollContainer, Table, TableHead, TableRow, TableHeader, TableBody } from '../styles/AssetsTableBodyStyles'; 
import AssetRow from './AssetRow';

const AssetsTableBody = ({ combinedData = [], setCombinedData, setEditedMaterialSetIds, handleImageClick, isEditable }) => (
  <>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Images</TableHeader>
            <TableHeader>ID</TableHeader>
            <TableHeader>Name</TableHeader>
            <TableHeader>Floor Vector</TableHeader>
            <TableHeader>Prompt Description</TableHeader> 
            <TableHeader>Description</TableHeader> 
            <TableHeader>Keywords</TableHeader>
            <TableHeader>Cost</TableHeader>
            <TableHeader>Producer</TableHeader> 
            <TableHeader>Designer</TableHeader> 
            <TableHeader>Page Link</TableHeader> 
            <TableHeader>Cover Image</TableHeader> 
            <TableHeader>L(cm)</TableHeader> 
            <TableHeader>W(cm)</TableHeader> 
            <TableHeader>H(cm)</TableHeader> 
          </TableRow>
        </TableHead>
        <TableBody>
          {combinedData.map((item, index) => (
            <AssetRow
              key={item.materialSetId}
              asset={item}
              index={index}
              setCombinedData={setCombinedData}
              setEditedMaterialSetIds={setEditedMaterialSetIds}
              handleImageClick={handleImageClick}
              isEditable={isEditable}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    
    <HorizontalScrollContainer>
      {/* Add an empty div or content to force horizontal scrollbar visibility */}
    </HorizontalScrollContainer>
  </>
);

export default AssetsTableBody;
