import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

export const useApiClient = () => {
    const navigate = useNavigate();

    const apiClient = useMemo(() => {
        const instance = axios.create({
            baseURL: 'https://test.versy.tech/api',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            }
        });

        instance.interceptors.response.use(
            response => response,
            error => {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('access_token');
                    navigate('/login');
                }
                return Promise.reject(error);
            }
        );

        return instance;
    }, [navigate]);

    return apiClient;
};
